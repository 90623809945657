import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    selectedItem: IMenuItem;
    
    constructor() {
    }

    defaultMenu: IMenuItem[] = [
        {   
            name: 'Dashboard',
            type: 'dropDown',
            icon: 'i-Bar-Chart',
            sub: [
                { icon: 'i-University', name: 'Admin Dashboard', state: '/dashboard/v1', type: 'link' },
                { icon: 'i-Administrator', name: 'Professor Dashboard', state: '/dashboard/v2', type: 'link' },
                { icon: 'i-Book', name: 'Student Dashboard', state: '/dashboard/v3', type: 'link' },
                // { icon: '', name: 'Version 4', state: '/dashboard/v4', type: 'link' },
            ]
        },
       /*  {
            name: 'UI kits',
            type: 'dropDown',
            icon: 'i-Library',
            sub: [
                { icon: 'i-Bell', name: 'Alerts', state: '/uikits/alerts', type: 'link' },
                { icon: 'i-Split-Horizontal-2-Window', name: 'Accordions', state: '/uikits/accordions', type: 'link' },
                { icon: 'i-Medal-2', name: 'Badges', state: '/uikits/badges', type: 'link' },
                {
                    icon: 'i-Arrow-Right-in-Circle',
                    name: 'Buttons',
                    type: 'dropDown',
                    sub: [
                        { name: 'Bootstrap Buttons', state: '/uikits/buttons', type: 'link' },
                        { name: 'Loding Buttons', state: '/uikits/buttons-loading', type: 'link' }
                    ]
                },
                { icon: 'i-ID-Card', name: 'Cards', state: '/uikits/cards', type: 'link' },
                { icon: 'i-Line-Chart-2', name: 'Cards metrics', state: '/uikits/cards-metrics', type: 'link' },
                { icon: 'i-Credit-Card', name: 'Cards widget', state: '/uikits/cards-widget', type: 'link' },
                { icon: 'i-Full-Cart', name: 'Cards ecommerce', state: '/uikits/cards-ecommerce', type: 'link' },
                { icon: 'i-Duplicate-Window', name: 'Modals', state: '/uikits/modals', type: 'link' },
                { icon: 'i-Speach-Bubble-3', name: 'Popover', state: '/uikits/popover', type: 'link' },
                { icon: 'i-Like', name: 'Rating', state: '/uikits/rating', type: 'link' },
                { icon: 'i-Loading-3', name: 'Loaders', state: '/uikits/loaders', type: 'link' },
            ]
        }, */
        {
            name: 'Event Managment',
            type: 'dropDown',
            icon: 'i-Calendar',
            sub: [
            
                { icon: 'i-Calendar', name: 'Calendar', state: '/calendar', type: 'link' },
            ]
        },
        {
            name: 'Apps',
            type: 'dropDown',
            icon: 'i-Computer-Secure',
            sub: [
                { icon: 'i-Add-File', name: 'Invoice Builder', state: '/invoice', type: 'link' },
                { icon: 'i-Speach-Bubble-3', name: 'Chat', state: '/chat', type: 'link' },
            ]
        },
        
       /*  {
            name: 'Forms',
            type: 'dropDown',
            icon: 'i-File-Clipboard-File--Text',
            sub: [
                { icon: 'i-File-Clipboard-Text--Image', name: 'Basic components', state: '/forms/basic', type: 'link' },
                { icon: 'i-Split-Vertical', name: 'Form layouts', state: '/forms/layouts', type: 'link' },
                { icon: 'i-Receipt-4', name: 'Input Group', state: '/forms/input-group', type: 'link' },
                { icon: 'i-File-Edit', name: 'Input Mask', state: '/forms/input-mask', type: 'link' },
                { icon: 'i-Tag-2', name: 'Tag Input', state: '/forms/tag-input', type: 'link' },
                { icon: 'i-Width-Window', name: 'Wizard', state: '/forms/wizard', type: 'link' },
                { icon: 'i-Crop-2', name: 'Image Cropper', state: '/forms/img-cropper', type: 'link' },
            ]
        }, */
        {
            name: 'Class',
            type: 'dropDown',
            icon: 'i-Library',
            sub: [
                { icon: 'i-Class', name: 'Class', state: '/classes/Class', type: 'link' },
              
            ]
        }, 
        {
            name: 'Gestion des Cours',
            type: 'dropDown',
            icon: 'i-Book',
            sub: [
                { icon: 'i-Book', name: 'Course List', state: '/courses/list', type: 'link' },
                { icon: 'i-Book', name: 'Course Detail', state: '/courses/detail/:id', type: 'link' },
                { icon: 'i-Add', name: 'Create Course', state: '/courses/create', type: 'link' },
                { icon: 'i-Add-User', name: 'Enroll User', state: '/courses/enroll', type: 'link' },
                { icon: 'i-Folder', name: 'Course Materials', state: '/courses/materials', type: 'link' }
            ]
        },
        
        
        {
            name: 'Sessions',
            type: 'dropDown',
            icon: 'i-Checked-User',
            sub: [
                { icon: 'i-Add-User', name: 'Sign up', state: '/sessions/signup', type: 'link' },
                { icon: 'i-Checked-User', name: 'Sign in', state: '/sessions/signin', type: 'link' },
                { icon: 'i-Find-User', name: 'Forgot', state: '/sessions/forgot', type: 'link' }
            ]
        },
        {
            name: 'Pages',
            type: 'dropDown',
            icon: 'i-Windows-2',
            sub: [
                { icon: 'i-Male', name: 'User Profile', state: '/pages/profile', type: 'link' }
            ]
        },
        {
            name: 'Email',
            type: 'dropDown',
            icon: 'i-Mail',
            sub: [
                { icon: 'i-Email', name: 'Inbox', state: '/inbox', type: 'link' },
            ]
        },
        {
            name: 'Schedule',
            type: 'dropDown',
            icon: 'i-Calendar-3',
            sub: [
                { icon: 'i-Calendar-3', name: 'Schedule-managment', state: '/schedules/manage', type: 'link' },


            ]
          },

        {
            name: 'Notification',
            type: 'dropDown',
            icon: 'i-Bell',
            sub: [
                { icon: 'i-Bell', name: 'Notification', state: '/notifications/notif', type: 'link' }
            ]
        },
        {
            name: 'Assessment Management',
            type: 'dropDown',
            icon: 'i-Check',
            sub: [
                { icon: 'i-List', name: 'Quiz/Exam List', state: '/assessment-management/quiz-exam-list', type: 'link' },
                { icon: 'i-Details', name: 'Quiz/Exam Detail', state: '/assessment-management/quiz-exam-detail/:id', type: 'link' },
                { icon: 'i-Edit', name: 'Quiz/Exam Creation/Edit', state: '/assessment-management/quiz-exam-create-edit', type: 'link' },
                { icon: 'i-Grade', name: 'Grading', state: '/assessment-management/grading', type: 'link' },
            ]
        },
        {
            name: 'Communication Tools',
            type: 'dropDown',
            icon: 'i-Cloud-Email',
            sub: [
                { icon: 'i-Forum', name: 'Discussion Forum', state: '/communication-tools/discussion-forum', type: 'link' },
                { icon: 'i-Messaging', name: 'Messaging', state: '/communication-tools/messaging', type: 'link' },
                { icon: 'i-Announcement', name: 'Announcement', state: '/communication-tools/announcement', type: 'link' },
            ]
        },
        {
            name: 'Reporting and Analytics',
            type: 'dropDown',
            icon: 'i-Memory-Card',
            sub: [
                { icon: 'i-Dashboard', name: 'Reports Dashboard', state: '/reporting-analytics/reports-dashboard', type: 'link' },
                { icon: 'i-Report', name: 'Report Generation', state: '/reporting-analytics/report-generation', type: 'link' },
                { icon: 'i-Analytics', name: 'Analytics', state: '/reporting-analytics/analytics', type: 'link' },
            ]
        },
        
    ];


    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    // You can customize this method to supply different menu for
    // different user type.
    // publishNavigationChange(menuType: string) {
    //   switch (userType) {
    //     case 'admin':
    //       this.menuItems.next(this.adminMenu);
    //       break;
    //     case 'user':
    //       this.menuItems.next(this.userMenu);
    //       break;
    //     default:
    //       this.menuItems.next(this.defaultMenu);
    //   }
    // }
}
